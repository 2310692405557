@import '~theme/variables';

$input-container-height: 7.7rem;

.lightTheme {
  &.container {
    background-color: $color-neutral-06;
    &:after {
      display: none;
    }
  }
}

.container {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: $space-xl;
  margin-top: -9rem;
  margin-bottom: 2.2rem;
  background-color: $color-neutral-01;
  z-index: $z-index-normal;

  @include breakpoint(sm, max) {
    padding-bottom: $space-l;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -22px;
    left: 0;
    right: 0;
    height: 22px; // original height of the image
    background-image: url('../../../../../public/static/images/shared/header-stripes-black.svg');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: $z-index-header;
  }
}

.titleWrapper {
  width: 100%;
  padding: 0 $space-s;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: $z-index-above;
}

.title {
  font-family: $font-family-display;
  font-size: 7.9rem;
  letter-spacing: -0.015em;
  line-height: 6.7rem;
  color: $color-neutral-06;

  @include breakpoint(sm, max) {
    font-size: 6rem;
    line-height: 4.8rem;
  }
}

.subtitle {
  font-size: 2.2rem;
  letter-spacing: -0.015em;
  line-height: 2.9rem;
  color: $color-neutral-06;
  margin-top: $space-s;
  @include breakpoint(sm, max) {
    line-height: 2rem;
  }
}

.videoBackground {
  width: 100%;

  height: auto;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 3.86%,
    rgba(0, 0, 0, 0) 94.2%
  );

  & video {
    z-index: $z-index-background;
    position: relative;
    height: 69vh;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 9/16;

    border-bottom-left-radius: 48px;
    border-bottom-right-radius: 48px;
    @include breakpoint(sm, max) {
      height: 61vh;
    }
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $space-s;

  @include breakpoint(sm, max) {
    margin-top: $space-m;
    flex-direction: column;
    padding: 0 $space-s;
  }
}

.button {
  @include breakpoint(sm, max) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.primaryButton {
    margin-right: $space-s;

    @include breakpoint(sm, max) {
      margin-right: 0;
      margin-bottom: $space-s;
    }
  }
}

.mobileStickerButton {
  display: none;
  @include breakpoint(sm, max) {
    display: block;
    margin-top: $space-l;
    width: 8rem;
  }
}

.inputWrapper {
  position: relative;
  min-height: $input-container-height;
  width: 100%;
  padding: 0 $space-s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(#{$input-container-height} / -2);
}
