@import '~theme/variables';
@import '../VideoPageHeader.module.scss';

.displayNone {
  display: none;
}

.mobileStickerButton {
  & .stickerButton {
    width: 8rem;
  }

  @include breakpoint(md, max) {
    display: block;
    margin-top: $space-l;
    width: 8rem;
  }
}

.desktopStickerButton {
  width: 9.4rem;
  @include breakpoint(md) {
    display: block;
    position: absolute;
    top: 0;
    right: 8%;
  }

  @include breakpoint(lg) {
    display: block;
    position: absolute;
    top: 0;
    right: 15%;
  }
}
