@import '~theme/variables';
@import '../VideoPageHeader.module.scss';

.lightTheme {
  .links {
    a,
    span {
      color: $color-neutral-01;

      &:hover {
        color: $color-primary;
      }
    }
  }
}

.body {
  width: 100%;
  padding: 0 $space-s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include breakpoint(sm) {
    width: 80%;
  }

  @include breakpoint(md) {
    width: 60%;
  }

  @include breakpoint(lg) {
    width: 40%;
  }
}

.inputContainer {
  display: flex;
  background-color: $color-neutral-06;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4.8rem;
  border: 1px $color-neutral-04 solid;
  height: $input-container-height;
  padding-left: 3.5rem;
  padding-right: 1.5rem;

  @include breakpoint(sm, max) {
    height: 6.4rem;
    padding-left: 1rem;
  }
}

.input {
  background-color: transparent;
  flex-grow: 1;
  border: none;
  padding: 1.2rem;
  width: 100%;
  background-color: transparent;
  caret-color: $color-primary;
  font-family: inherit;
  line-height: 2.8rem;
  font-size: inherit;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $color-neutral-02;
  }
}

.input-container {
  flex-grow: 1;
  margin-right: 0.8rem;
}

.searchButton {
  width: 5.6rem;
  height: 5.4rem;
  border-radius: 3.2rem;
  svg {
    font-size: 2.4rem;
  }

  @include breakpoint(sm, max) {
    width: 5rem;
    height: 4.4rem;

    svg {
      font-size: 2rem;
    }
  }
}
.links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 $space-s;
  margin-top: $space-s;
  width: 100%;
  padding: 0 $space-m;

  div {
    margin-right: 2.5rem;
  }

  span,
  a {
    color: $color-neutral-06;
    font-size: 1.6rem;
    line-height: 2.5rem;
    font-weight: 400;
  }
  a {
    text-decoration: underline;
  }

  @include breakpoint(sm, max) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      text-align: center;
      margin-bottom: $space-xs;
    }
  }
}

.locationButton {
  text-decoration: underline;
  span {
    transition: color $transition-01;
  }

  &:hover {
    span {
      color: $color-primary;
    }
  }
}
