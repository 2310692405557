@import '~theme/variables';

.pageTitle {
  position: relative;
  font-family: $font-family-display;
  font-size: 7.9rem;
  letter-spacing: -0.015em;
  line-height: 6.7rem;
  color: $color-neutral-06;

  @include breakpoint(sm, max) {
    font-size: 6rem;
    line-height: 4.8rem;
  }
  &.dark {
    color: $color-neutral-01;
  }
}

.highlightedText {
  color: $color-neutral-01;
  position: relative;
  white-space: pre;
  word-break: break-word;

  & svg {
    position: absolute;
    z-index: -1;
    right: -2%;
    bottom: 0.5rem;
    height: 7rem;
    width: 104%;

    @include breakpoint(sm, max) {
      height: 5.5rem;
    }
  }
}
